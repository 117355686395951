import { baseUrl } from "../../config";

const getAccounts = async () => {
  const res = await fetch(`${baseUrl}/v1/accounts/`, {
    method: "GET",
  });
  if (!res.ok) {
    console.error("Failed to fetch accounts list", res);
    throw new Error("Failed to fetch accounts list");
  }
  const data = await res.json();
  return data;
};

const getAccount = async (id) => {
  const res = await fetch(`${baseUrl}/v1/accounts/${id}`, {
    method: "GET",
  });
  if (!res.ok) {
    console.error("Failed to fetch account", res);
    throw new Error("Failed to fetch account");
  }
  const data = await res.json();
  return data;
};

const getPermissions = async () => {
  const res = await fetch(`${baseUrl}/v1/accounts/permissions`, {
    method: "GET",
  });
  if (!res.ok) {
    console.error("Failed to fetch permissions", res);
    throw new Error("Failed to fetch permissions");
  }
  const data = await res.json();
  return data;
};

const getRoles = async () => {
  const res = await fetch(`${baseUrl}/v1/accounts/roles`, {
    method: "GET",
  });
  if (!res.ok) {
    console.error("Failed to fetch roles", res);
    throw new Error("Failed to fetch roles");
  }
  const data = await res.json();
  return data;
};

const deleteAccount = async (id) => {
  const res = await fetch(`${baseUrl}/v1/accounts/${id}`, {
    method: "DELETE",
  });
  if (!res.ok) {
    console.error("Failed to delete acount", res);
    throw new Error("Failed to delete account");
  }
};

const setAccount = async (payload) => {
  await fetch(`${baseUrl}/v1/accounts/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const editAccount = async (payload, id) => {
  await fetch(`${baseUrl}/v1/accounts/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(payload),
  });
}



export { getAccounts, getAccount, deleteAccount, getPermissions, getRoles, setAccount, editAccount };
