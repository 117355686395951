import _ from 'lodash';
import commonJson from './env/common.env.json';

const SC_ENV = process.env.REACT_APP_ENVIRONMENT || process.env.NODE_ENV;

// Get env json depending on env
let overrideJson = require('./env/' + SC_ENV + '.env.json');
let config = _.merge(commonJson, overrideJson);

const awsConfig = config.awsConfig;
const buckets = config.awsBuckets;
const baseUrl = config.baseUrl;
const authUrl = config.authUrl;
let awsVideoBaseUrl = config.awsVideoBaseUrl;
let awsImageBaseUrl = config.awsImageBaseUrl; 

if (SC_ENV === 'development') {
  awsVideoBaseUrl = `${awsConfig.endpoint}/${buckets.video}`;
}

export {
  awsConfig,
  buckets,
  baseUrl,
  authUrl,
  awsImageBaseUrl,
  awsVideoBaseUrl,
  SC_ENV
};
