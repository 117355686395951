import { baseUrl } from "../../config";

const updateUserInfo = async () => {
  const res = await fetch(`${baseUrl}/v1/accounts/me`);
  if(!res.ok) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
  } else {
    const data = await res.json();
    localStorage.setItem('user', JSON.stringify(data));
  }
};

export { updateUserInfo };