import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './small.css';
import './assets/fonts/Montserrat/Montserrat-Regular.ttf';
import App from './App';
import * as serviceWorker from './serviceWorker';

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  const accessToken = localStorage.getItem('accessToken');
  let [resource, config] = args;
  if(accessToken) {
    config = config || {};
    config.headers = config.headers || {};
    config.headers.authorization = `Bearer ${accessToken}`;
  }
  const response = await originalFetch(resource, config);
  if((response.status === 401 || response.status === 403) && !window.location.href.includes('/authenticate')) {
    window.location.replace('/authenticate');
  }
  return response;
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
